<template>
  <div>
    <v-autocomplete
      v-model="item_id"
      :item-value="'node.id'"
      :filter="customFilter"
      :search-input.sync="search"
      hide-no-data
      :items="items_list"
      :loading="loading"
      :label="label"
      :readonly="readonly"
      @change="item_change"
      :clearable="clearable"
      :rules="[(v) => (rules ? !!v : true) || label + ' obligatoire']"
      @input="item_select"
      @click:clear="clear_click"
      dense
      v-if="!list_items"
    >
      <template v-slot:selection="{ item: { node } }">
        <!-- HTML that describe how select should render selected items -->
        {{ node ? list_label(node) : item_id }}
      </template>
      <template v-slot:item="{ item: { node } }">
        <v-list-item-content>
          <v-list-item-title v-html="list_label(node)"></v-list-item-title>
          <v-list-item-subtitle
            v-if="subtitle"
            v-html="
              (node.unit_name ? node.unit_name : 'Quantité') +
              ' : ' +
              (node.stock_cmpt ? node.stock_cmpt : 0)
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:append-item="">
        <div v-intersect="endIntersect"></div>
      </template>
    </v-autocomplete>
    <v-autocomplete
      v-model="item_id"
      :item-value="item_value"
      :item-text="item_text"
      :filter="customFilter"
      :search-input.sync="search"
      hide-no-data
      :items="items_list"
      :loading="loading"
      :label="label"
      :readonly="readonly"
      @change="item_change"
      :clearable="clearable"
      :rules="[(v) => (rules ? !!v : true) || label + ' obligatoire']"
      @input="item_select"
      @click:clear="clear_click"
      dense
      v-if="list_items"
    >
    </v-autocomplete>
  </div>
</template>

<script>
const RESULTS_TO_SHOW = 10;
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  components: {},
  name: "select_cursor",
  props: {
    items: Array,
    Qsearch: Object,
    Qresp: String,
    field: String,
    value: [Object, String],
    text_fields: Array,
    list_items: Array,
    whereadd: Array,
    variableadd: Object,
    label: String,
    readonly: Boolean,
    subtitle: { type: Boolean, default: false },
    rules: { type: Boolean, default: true },
    clearable: { type: Boolean, default: false },
    item_value: { type: String, default: "id" },
    item_text: { type: String, default: "label" },
  },
  data: () => ({
    isIntersecting: null,
    item_id: null,
    pageInfo: null,
    search: "",
    loading: false,
    cc: false,
    list: [],
  }),

  computed: {
    items_list() {
      let l = this.list;
      if (this.items)
        l = this.list.filter(
          (elm) =>
            !this.items.map((elm2) => elm2[this.field]).includes(elm.node.id) ||
            elm.node.id == this.value
        );
      return l;
    },
    where() {
      var where = null;
      if (this.value && !this.isIntersecting && !this.cc) {
        where = {
          column: "ID",
          value: this.value,
        };
      }
      if (this.search) {
        var Wherelist = [];
        var x = "%";
        x = x.concat(this.search, "%");
        this.text_fields.forEach((element) => {
          Wherelist.push({
            column: element.toUpperCase(),
            operator: "LIKE",
            value: x,
          });
        });

        if (Wherelist.length) where = { OR: Wherelist };
      }
      if (this.whereadd) {
        let l = [];
        this.whereadd.forEach((element) => {
          l.push(element);
        });
        if (where) l.push(where);
        where = { AND: l };
      }
      return where;
    },
  },
  watch: {
    search: debounce(function () {
      this.get_list();
    }, 1000),
  },

  created() {},
  mounted() {
    if (this.value) {
      if (this.list_items) this.item_id = this.value;
      else this.item_id = { node: { id: this.value } };
    }
    if (this.list_items) {
      this.list = this.list_items;
    }
  },

  methods: {
    clear_click() {
      this.cc = true;
      this.get_list();
    },
    item_change(item) {
      if (!item) this.get_list();
      let list = [];
      this.items_list.forEach((element) => {
        list.push(element.node);
      });
      this.$emit("change", item, !this.list_items ? list : null);
    },
    list_label(node) {
      let l = "";
      if (node)
        for (let index = 0; index < this.text_fields.length; index++) {
          const element = this.text_fields[index];
          if (index < this.text_fields.length - 1)
            l = l + (node[element] ? node[element] + "-" : "");
          else l = l + (node[element] ? node[element] : "");
        }

      return l;
    },
    variable(after) {
      let w = {
        where: this.where,
        first: RESULTS_TO_SHOW,
        after: after,
      };
      if (this.variableadd)
        for (var [cle, valeur] of Object.entries(this.variableadd)) {
          w[cle] = valeur;
        }
      return w;
    },
    customFilter(item, queryText) {
      let textlist = [];
      this.text_fields.forEach((element) => {
        let text1;
        if (this.list_items)
          text1 = item[element] ? item[element].toLowerCase() : "";
        else text1 = item.node[element] ? item.node[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    async get_list() {
      //if (this.loading) return;
      if (!this.list_items) {
        this.isIntersecting = false;
        let r = await this.requette(this.Qsearch, this.variable(null));
        if (r) {
          this.list = r[this.Qresp].edges;
          this.pageInfo = r[this.Qresp].pageInfo;
          if (this.value) {
            let list = [];
            this.list.forEach((element) => {
              list.push(element.node);
            });
            //this.$emit("change", this.value, list);
          }
        }
      }
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (
        isIntersecting &&
        this.pageInfo &&
        this.pageInfo.hasNextPage &&
        !this.loading
      ) {
        this.isIntersecting = true;
        let r = await this.requette(
          this.Qsearch,
          this.variable(this.pageInfo.endCursor)
        );
        if (r) {
          let moreLists = r[this.Qresp].edges;
          this.list = [...this.list, ...moreLists];
          this.pageInfo = r[this.Qresp].pageInfo;
        }
      }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "network-only",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    item_select() {
      this.cc = true;
      if (!this.list_items) {
        if (this.item_id) {
          let i = this.items_list.findIndex(
            (elm) =>
              elm.node.id ==
              (this.item_id.node ? this.item_id.node.id : this.item_id)
          );

          this.$emit(
            "item_select",
            i == -1 ? this.item_id : this.items_list[i].node
          );
          this.$emit("input", this.item_id);
        } else this.$emit("item_select", null);
      }
    },
  },
};
</script>
